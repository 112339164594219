import React, { useEffect, useState } from 'react'
import './TopMenu.scss'
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import tuskSvg from '../../assets/images/tusk.svg'
import LanguageChooser from '../../components/LanguageChooser/LanguageChooser'
import DataProvider from '../../services/DataProvider'
import androidIcon from '../../assets/images/android_icon.png'
import appleIcon from '../../assets/images/apple_icon.png'
import windowsIcon from '../../assets/images/windows_icon.png'
import { useTranslation, withTranslation } from 'react-i18next'
import LoginButton from '../../components/LoginButton/LoginButton'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../services/firebase'

function TopMenu() {
  const [user, loading] = useAuthState(auth)
  const {t} = useTranslation()

  return (
    <Navbar bg="light" expand="lg" id="top-menu" className="py-0 py-lg-1">
      <Container fluid={ true } className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <LinkContainer to="/">
            <Navbar.Brand href="/" className="d-flex">
              <img src={ tuskSvg } alt="Tusk logo" width="32"/>
              <span>Tusk</span>
            </Navbar.Brand>
          </LinkContainer>
          <LinkContainer to="/">
            <Nav.Link href="/" className="d-none d-md-inline">{ t('home') }</Nav.Link>
          </LinkContainer>
          <NavDropdown title={ t('download') } id="basic-nav-dropdown">
            <li>
              <a className="dropdown-item" target="_blank" rel="noreferrer"
                 title={ t('android_version') }
                 href={ DataProvider.linkDownloadAndroid }>
                <img src={ androidIcon } alt={ t('android_version') } height="16" width="16" className="me-2"/>
                { t('download_for_android') }
              </a>
            </li>

            <li>
              <a className="dropdown-item" target="_blank" rel="noreferrer"
                 title={ t('ios_version') }
                 href={ DataProvider.linkDownloadIOS }>
                <img src={ appleIcon } alt={ t('ios_version') } height="16" width="16" className="me-2"/>
                { t('download_for_ios') }
              </a>
            </li>

            <li>
              <a className="dropdown-item" target="_blank" rel="noreferrer"
                 title={ t('windows_version') }
                 href={ DataProvider.linkDownloadWindows }>
                <img src={ windowsIcon } alt={ t('windows_version') } height="16" width="16" className="me-2"/>
                { t('download_for_windows') }
              </a>
            </li>
          </NavDropdown>
          { user !== null && <LinkContainer to="/account">
            <Nav.Link
              href="/account">{ t('account') }
              <span className="d-none d-md-inline">
                &nbsp;({ (user?.displayName ?? user.providerData[0]?.displayName) ?? user?.email })
              </span>
            </Nav.Link>
          </LinkContainer> }
        </div>
        <div className="d-flex align-items-center">
          <div>
            <LoginButton/>
          </div>
          <LanguageChooser/>
        </div>
      </Container>
    </Navbar>
  )
}

export default withTranslation()(TopMenu)