import React from 'react'
import {store} from '../../services/store'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {Provider} from 'react-redux'
import Home from '../../pages/Home/Home'
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy'
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions'
import Page from '../Page/Page'
import Login from '../../pages/Login/Login'
import Register from '../../pages/Register/Register'
import Reset from '../../pages/Reset/Reset'
import Account from '../../pages/Account/Account'
import Success from '../../pages/Success/Success'
import AccountDeletion from '../../pages/Faq/AccountDeletion'


function App() {
  return (
    <div className="App" id="app">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Page/>}>
              <Route path="/" element={<Home/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/register" element={<Register/>}/>
              <Route path="/reset" element={<Reset/>}/>
              <Route path="/account" element={<Account/>} />
              <Route path="/account/success" element={<Success/>} />
              <Route path="/privacy-policy.html" element={<PrivacyPolicy/>}/>
              <Route path="/privacy_policy" element={<PrivacyPolicy/>}/>
              <Route path="/terms-and-conditions.html" element={<TermsAndConditions/>}/>
              <Route path="/terms_and_conditions" element={<TermsAndConditions/>}/>
              <Route path="/account_deletion" element={<AccountDeletion/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  )
}

export default App
