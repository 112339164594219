import React, { useEffect } from 'react'
import { getI18n, Trans, useTranslation, withTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExpiresAsync, selectExpires, selectExpiresLoadingStatus } from '../Account/accountSlice'
import { auth } from '../../services/firebase'
import moment from 'moment'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import "./Success.scss"

function Success(props) {
  const dispatch = useDispatch()
  const premiumExpires = useSelector(selectExpires)
  const expiresLoadingStatus = useSelector(selectExpiresLoadingStatus)
  const {t} = useTranslation()
  const lang = getI18n().resolvedLanguage
  moment.locale(lang)
  const [user] = useAuthState(auth)
  const navigate = useNavigate()

  let firstLoad = true
  useEffect(() => {
    if (user === null) {
      setTimeout(() => {
        if (auth.currentUser === null) {
          navigate('/')
        }
      }, 5000)
      return
    }

    if (firstLoad && premiumExpires === null && expiresLoadingStatus === 'idle') {
      firstLoad = false
      dispatch(fetchExpiresAsync(user.uid))
    }
  }, [user])

  let premiumExpiresData = ''
  if (premiumExpires != null && moment(premiumExpires).isAfter(moment.now())) {
    let expires = moment(premiumExpires)
    if (expires.isSameOrAfter(moment('2030-01-01'))) {
      premiumExpiresData = (
        <p><b>{ t('lifetime_premium_activated') }</b></p>
      )
    } else {
      premiumExpiresData = (
        <p><b>{ t('premium_is_active_until') } <Moment format="L" locale={ lang }>{ expires }</Moment></b></p>
      )
    }
  }

  return <BasicPage title={ t('premium_purchased') }>
    <div className="account">
      <Container>
        <Row>
          <Col xs={ 0 } md={ 1 } lg={ 2 } xl={ 3 }/>
          <Col>
            <Container className="account__details text-start">
              <Row>
                <Col>
                  <h2 className="golden">{ t('premium_purchased') }</h2>
                  <p>{ user?.email }</p>
                  <p>{ t('premium_purchased_description') }</p>
                  <p>
                    <strong>{ premiumExpiresData }</strong>
                  </p>
                  <hr className="my-4"/>
                  <p>
                    <i>
                      <Trans i18nKey="if_you_have_questions">
                        If you have questions about the premium or the application, please contact us at <a
                        href="mailto:support@tusk.best">support@tusk.best</a>.
                      </Trans>
                    </i>
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={ 0 } md={ 1 } lg={ 2 } xl={ 3 }/>
        </Row>
      </Container>
    </div>
  </BasicPage>
}

export default Success