import React from 'react'
import reactSvg from '../../assets/images/tusk.svg'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import './Footer.scss'

class Footer extends React.Component {
  render() {
    const {t} = this.props
    return <footer className="d-flex align-items-center justify-content-center flex-column-reverse flex-md-row">
      <img src={ reactSvg } alt="Tusk logo" height="25" className="mb-4 my-md-0"/>
      <div className="d-inline-flex pl-2 mb-1 mt-2 my-md-0">© 2022 ULSoft</div>
      <span className="d-block px-2 d-none d-md-block">|</span>
      <Link to="/privacy_policy" className="my-1 my-md-0">{ t('privacy_policy') }</Link>
      <span className="d-block px-2 d-none d-md-block">|</span>
      <Link to="/terms_and_conditions" className="my-1 my-md-0">{ t('terms_and_conditions') }</Link>
      <span className="d-block px-2 d-none d-md-block">|</span>
      <a href="mailto:support@tusk.best" className="mb-1 mt-3 my-md-0">{ t('contact_us') }</a>
      <span className="d-block px-2 d-none d-md-block">|</span>
      <Link to="/account_deletion" className="my-1 my-md-0">{ t('account_deletion') }</Link>
    </footer>
  }
}

export default withTranslation()(Footer)