import React from 'react'
import { getI18n, withTranslation } from 'react-i18next'
import PrivacyPolicyRu from './PrivacyPolicyRu'
import PrivacyPolicyEn from './PrivacyPolicyEn'

class PrivacyPolicy extends React.Component {
  render() {
    const lang = getI18n().resolvedLanguage
    const {t} = this.props

    if (lang === 'ru') {
      return <PrivacyPolicyRu title={ t('privacy_policy') }/>
    }

    return <PrivacyPolicyEn title={ t('privacy_policy') }/>
  }
}

export default withTranslation()(PrivacyPolicy)