import React from 'react'
import Flag from 'react-world-flags'
import { getI18n } from 'react-i18next'
import './LanguageChooser.scss'

const countryFlagSize = 20

class LanguageChooser extends React.Component {

  constructor(props) {
    super(props)
    this.chooseLanguage = this.chooseLanguage.bind(this)
  }

  chooseLanguage(newLang) {
    getI18n().changeLanguage(newLang)
  }

  render() {
    const lang = getI18n().resolvedLanguage

    return (
      <div className="language-chooser">
        <span></span>
        <a className={ lang === 'ru' ? 'selected' : '' } onClick={ () => this.chooseLanguage('ru') }
           href="#">
          <Flag code="ru" height={ countryFlagSize }/>
        </a>
        <div className="mx-1"></div>
        <a className={ lang !== 'ru' ? 'selected' : '' } onClick={ () => this.chooseLanguage('en') }
           href="#">
          <Flag code="us" height={ countryFlagSize }/>
        </a>
      </div>
    )
  }
}

export default LanguageChooser