import React from 'react'
import Footer from '../Footer/Footer'
import { Outlet } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import TopMenu from '../TopMenu/TopMenu'
import './BasicPage.scss'

class BasicPage extends React.Component {
  componentDidMount() {
    document.title = this.props.title != null ? ('Tusk :: ' + this.props.title ) : "Tusk"
  }

  render() {
    return (
      <div className="basic-page">
        <TopMenu/>
        <div className="wrap">
          { this.props.children }
          <Outlet/>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default withTranslation()(BasicPage)