class DataProvider {

  static get linkDownloadAndroid() {
    return 'https://play.google.com/store/apps/details?id=com.ulsoft.tusk'
  }

  static get linkDownloadIOS() {
    return 'https://apps.apple.com/bm/app/tusk-flexible-task-manager/id1586005127'
  }

  static get linkDownloadWindows() {
    return 'https://tusk.best/desktop/tusk_1.1.100.msix'
  }
}

export default DataProvider