import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Link, useNavigate } from 'react-router-dom'
import {
  auth,
  registerWithEmailAndPassword,
} from '../../services/firebase'
import './Register.scss'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Modal } from 'react-bootstrap'
import validator from 'validator'

function Register() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [user, loading] = useAuthState(auth)
  const [showDialog, setShowDialog] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [loginError, setLoginError] = useState(null)

  const handleClose = () => {
    setShowDialog(false)
    navigate('/login')
  }
  const navigate = useNavigate()
  const register = async () => {
    const emailFailed = !validator.isEmail(email)
    const passwordFailed = password.length < 6
    const nameFailed = name.length === 0
    setLoginError(null)
    setEmailError(emailFailed)
    setPasswordError(passwordFailed)
    setNameError(nameFailed)
    if (emailFailed || passwordFailed || nameFailed) {
      return
    }

    let message = await registerWithEmailAndPassword(name, email, password)
    if (message != null && message.indexOf('ema' +
      'il-already-in-use') >= 0) {
      message = t('email_is_already_in_use')
      setEmailError(true)
    }

    setLoginError(message)
  }
  const {t} = useTranslation()
  useEffect(() => {
    if (loading) return
    if (user) navigate('/account')
  }, [user, loading])
  return (
    <BasicPage>
      <div className="register">
        <div className="register__container">
          <input
            type="text"
            className={ 'register__textBox ' + (nameError ? 'is-invalid' : '') }
            value={ name }
            onChange={ (e) => setName(e.target.value) }
            placeholder={ t('your_name') }
          />

          { nameError && <p>{ t('enter_name') }</p> }

          <input
            type="text"
            className={ 'register__textBox ' + (emailError ? 'is-invalid' : '') }
            value={ email }
            onChange={ (e) => setEmail(e.target.value) }
            placeholder={ t('email') }
          />

          { emailError && <p>{ t('enter_valid_email') }</p> }

          <input
            type="password"
            className={ 'register__textBox ' + (passwordError ? 'is-invalid' : '') }
            value={ password }
            onChange={ (e) => setPassword(e.target.value) }
            placeholder={ t('password') }
          />

          { passwordError && <p>{ t('enter_password_register') }</p> }

          { loginError !== null && <p className="fw-bold">{ loginError }</p> }

          <button className="register__btn" onClick={ register }>
            { t('register') }
          </button>
          <div>
            <Trans i18nKey="already_have_an_account">
              Already have an account? <Link to="/login">Login</Link> now.
            </Trans>
          </div>
        </div>

        <Modal show={ showDialog } onHide={ handleClose }>
          <Modal.Header closeButton>
            <Modal.Title>{ t('email_has_been_sent') }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-start">
              { t('register_description') }
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={ handleClose }>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </BasicPage>
  )
}

export default Register