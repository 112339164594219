import React from 'react'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import { withTranslation } from 'react-i18next'

class AccountDeletion extends React.Component {
  render() {
    const {t} = this.props
    return (
      <BasicPage title={t('account_deletion')}>
        <div className="container" id="account-deletion">
          <div className="row">
                <div className="col">
                    <div className="text-left justify-content-start">
                    <h1>Account deletion</h1>
                    <h3>Delete manually</h3>
                    <p>
                        To delete your account, please perform the following steps: </p>
                        <ul>
                            <li>Enter the mobile application and go to the <b>Settings</b> tab.</li>
                            <li>If you're already signed in, click on the <b>"You've signed in as ..."</b> button.</li>
                            <li>If you're not signed in, click on the <b>"Sign in"</b> button and perform sign in using your credentials.</li>
                            <li>In the modal window that appears, you will see a <b>"Delete account"</b> button. Click on it and enter your email as a confirmation of account deletion.</li>
                            <li>This will completely delete your personal account data. For basic accounts this will be your email address (since this is the only data we store). If you had a premium account, we will also delete the encrypted archives of your tasks on our server.</li>
                        </ul>
                    <h3>Make a request</h3>
                    <p>
                    You can request deletion of your account and/or task archive on our server by contacting our support email: <a href="mailto:support@tusk.best">support@tusk.best</a>. We undertake to delete any of your data within 7 days from the date of your request.
                    </p>
                    <br />
                    <br />
                    <br />
                    </div>
                </div>
            </div>
        </div>
      </BasicPage>
    )
  }
}

export default withTranslation()(AccountDeletion)