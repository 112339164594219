import React from 'react'
import { Star } from 'react-feather'
import './BuyPremiumCard.scss'

function BuyPremiumCard(props) {

  return (
    <div className="premiumCard flex-md-row flex-column">
      {/*<Star size={ 100 }/>*/ }
      <span>{ props.label }</span>
        <p className="my-2">{ props.price > 0 && <strong>{ props.price }&nbsp;руб.</strong> }</p>
      <button className="btn btn-info" onClick={ props.onClick }>
        { props.price > 0 ? 'Купить' : 'Получить' }
      </button>
    </div>
  )
}

export default BuyPremiumCard