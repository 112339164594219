import React from 'react'
import './assets/scss/app.scss'
import App from './layouts/App/App'
import * as serviceWorker from './serviceWorker'
import {createRoot} from 'react-dom/client'
import './services/i18n.js'

const container = document.getElementById('root')

createRoot(container).render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
