import React from 'react'
import { Outlet } from 'react-router-dom'

class Page extends React.Component {
  render() {
    return (
      <Outlet />
    )
  }
}

export default Page