import React from 'react'
import { Link } from 'react-router-dom'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import { Col, Container, Row } from 'react-bootstrap'

class PrivacyPolicyRu extends React.Component {
  render() {
    return (
      <BasicPage title={ this.props.title }>
        <Container>
          <Row>
            <Col>
              <div className="pt-2 pt-md-5" id="privacy-policy">
                <div id="wbars">
                  <h1>Политика конфиденциальности</h1>
                  <div className="one_line_col">

                    <div className="iub_header">
                      <p> Tusk («мы», «наш» или «нас») обязуется защищать вашу конфиденциальность. В настоящей Политике
                        конфиденциальности объясняется, как Tusk собирает, использует и раскрывает вашу личную
                        информацию. </p>
                      <p> Настоящая Политика конфиденциальности применяется к нашему веб-сайту и связанным с ним поддоменам
                        (совместно именуемым «Сервис») вместе с нашим приложением Tusk. Получая доступ к нашему Сервису или
                        используя его, вы подтверждаете, что прочитали, поняли и согласны с нашим сбором, хранением,
                        использованием и раскрытием вашей личной информации, как описано в настоящей Политике
                        конфиденциальности и наших Условиях обслуживания. </p> <br/>
                      <p> Этот документ содержит <a href="#california_info" target="_self"> раздел, посвященный
                        калифорнийским потребителям и их правам на конфиденциальность </a>. </p>
                      <p> Этот документ содержит <a href="#brazil_info" target="_self"> раздел, посвященный пользователям
                        Бразилии и их правам на конфиденциальность </a>. </p>
                      <p> Этот документ можно распечатать для справки, используя команду печати в настройках любого
                        браузера. </p>
                    </div>

                    <h2 id="types_of_data">
                      Категории собираемых Данных
                    </h2>

                    <p>Владелец не предоставляет список собираемых категорий Персональных данных.</p>
                    <p>Полные сведения о каждой категории собранных Персональных данных приводятся в соответствующих
                      разделах
                      настоящей политики приватности или в специальных пояснительных текстах, которые выводятся на
                      экран
                      перед
                      сбором Данных.<br/>
                      Персональные данные могут предоставляться Пользователем самостоятельно, или же, в случае Данных
                      об
                      использовании, их сбор может автоматически осуществлять Приложение при его использовании.<br/>
                      Если не указано иное, все Данные, которые запрашивает Приложение, являются обязательными. В
                      случае
                      непредставления указанных Данных Приложение может оказаться не в состоянии предоставлять свои
                      услуги. В
                      тех
                      случаях, когда Приложение прямо указывает на необязательность представления определенных Данных,
                      Пользователи вправе не сообщать соответствующие Данные без каких-либо последствий в части,
                      касающейся
                      доступности или функционирования Сервиса.<br/>
                      Пользователям, не уверенным в том, какие Персональные данные являются обязательными,
                      рекомендуется
                      связаться
                      с Владельцем.<br/>
                      Приложение или владельцы сторонних сервисов, которые задействует Приложение, используют Файлы
                      «Cookie»
                      (или
                      другие средства отслеживания) исключительно в целях предоставления Сервиса, требующегося
                      Пользователю, а
                      также любых других целей, указанных в настоящем документе, и в Правилах в отношении
                      использования
                      Файлов
                      «Cookie» (если таковые имеются).</p>
                    <p>Пользователи несут ответственность за любые Персональные данные третьих лиц, которые они
                      получают,
                      публикуют
                      или предоставляют другим лицам, полученные, опубликованные или переданные через это Приложение,
                      и
                      подтверждают, что ими получено согласие соответствующего третьего лица на предоставление Данных
                      Владельцу.</p>
                  </div>
                  <div className="one_line_col">
                    <h2 id="place_of_processing">Метод и место обработки Данных</h2>
                    <h3>Методы обработки</h3>
                    <p>Владелец принимает адекватные меры безопасности для предотвращения несанкционированного доступа к
                      Данным,
                      их
                      разглашения, изменения или несанкционированного уничтожения.<br/>
                      Обработка Данных осуществляется с использованием компьютеров и (или) средств с поддержкой
                      информационных
                      технологий (ИТ) в соответствии с организационными процедурами и методами, строго связанными с
                      указанными
                      целями. В некоторых случаях Данные могут быть доступны, помимо Владельца, определенным
                      категориям
                      ответственных лиц, которые участвуют в работе этого Приложения (администрация, отдел продаж,
                      отдел
                      маркетинга, юридический отдел, системные администраторы), или сторонним организациям (таким как
                      сторонние
                      поставщики технических услуг, почтовые организации, поставщики услуг хостинга,
                      компании-поставщики
                      информационных технологий, службы связи), которым Владелец поручает, при необходимости,
                      выполнять
                      функции
                      Обработчика данных. Обновляемый перечень указанных лиц и сторон можно в любое время запросить у
                      Владельца.
                    </p>
                    <h3>Правовое основание обработки</h3>
                    <p>Владелец может обрабатывать Персональные данные, относящиеся к Пользователям, если применим один
                      из
                      нижеуказанных критериев:</p>
                    <ul>
                      <li>Пользователи дали свое согласие на использование Данных для одной или нескольких конкретных
                        целей.
                        Примечание: согласно законодательству некоторых юрисдикций Владельцу может быть разрешено
                        обрабатывать
                        Персональные данные до тех пор, пока Пользователь не возразит против этого («отказ»), без
                        необходимости
                        полагаться на согласие или иное другое правовое основание из числа нижеперечисленных. Вместе
                        с
                        тем
                        вышеуказанное не применимо, когда обработка Персональных данных регулируется европейским
                        законодательством о защите данных;
                      </li>
                      <li>предоставление Данных необходимо для исполнения соглашения с Пользователем и (или) любых
                        предшествующих
                        заключению договора обязательств;
                      </li>
                      <li>обработка необходима для выполнения установленной законом обязанности, которая возложена на
                        Владельца;
                      </li>
                      <li>обработка связана с той или иной задачей, которая осуществляется в общественных интересах
                        или
                        при
                        осуществлении официальных полномочий, возложенных на Владельца;
                      </li>
                      <li>обработка необходима в целях обеспечения законных интересов Владельца или третьей стороны.
                      </li>
                    </ul>
                    <p>В любом случае Владелец охотно поможет прояснить конкретное правовое основание, применимое к
                      обработке,
                      и, в
                      частности, является ли предоставление Персональных данных требованием, предусмотренным законом
                      или
                      договором, или требованием, необходимым для заключения договора.</p>
                    <h3>Место</h3>
                    <p>Данные обрабатываются в операционных офисах Владельца и в любых иных местах, где находятся
                      стороны,
                      занимающиеся их обработкой.<br/>
                      В зависимости от местонахождения Пользователя передача данных может предусматривать передачу
                      Данных
                      Пользователя в ту или иную страну за пределами его собственной страны. За более подробной
                      информацией о
                      месте обработки таких переданных Данных Пользователи могут обратиться к разделу, содержащему
                      подробные
                      сведения об обработке Персональных данных.
                    </p>
                    <p>Пользователи также имеют право ознакомиться с правовым основанием передачи Данных в ту или иную
                      страну,
                      не
                      входящую в Европейский союз, или в любую международную организацию, регулируемую публичным
                      международным
                      правом или созданную двумя или более странами, такую как ООН, а также с мерами безопасности,
                      принимаемыми
                      Владельцем для защиты их Данных.<br/><br/>
                      Если такая передача осуществляется, Пользователи могут получить дополнительную информацию,
                      ознакомившись
                      с
                      соответствующими разделами настоящего документа, или запросить её у Владельца, воспользовавшись
                      информацией,
                      представленной в разделе с контактными сведениями.</p>
                    <h3>Срок хранения</h3>
                    <p>Персональные данные должны обрабатываться и храниться до тех пор, пока они требуются для тех
                      целей,
                      для
                      которых они были собраны.</p>
                    <p>Таким образом:</p>
                    <ul>
                      <li>Персональные данные, собранные для целей, связанных с исполнением договора между Владельцем
                        и
                        Пользователем, сохраняются до тех пор, пока такой договор не будет исполнен в полном объёме.
                      </li>
                      <li>Персональные данные, собранные в целях обеспечения законных интересов Владельца, сохраняются
                        до
                        тех
                        пор,
                        пока они необходимы для осуществления таких целей. Пользователи могут найти конкретную
                        информацию о
                        законных интересах, преследуемых Владельцем, в соответствующих разделах настоящего
                        документа,
                        или
                        обратившись к Владельцу.
                      </li>
                    </ul>
                    <p>Владельцу может быть разрешено сохранять Персональные данные в течение более длительного периода
                      времени
                      в
                      том случае, если Пользователь дал согласие на такую обработку, до тех пор, пока такое согласие
                      не
                      будет
                      отозвано. Кроме того, Владелец может быть обязан сохранять Персональные данные в течение более
                      длительного
                      периода времени, если это требуется для выполнения установленной законом обязанности, или по
                      распоряжению
                      того или иного органа власти.<br/><br/>
                      По истечении срока хранения Персональные данные удаляются. Таким образом, право на доступ, право
                      на
                      удаление, право на исправление и право на перенос данных не могут быть реализованы по истечении
                      срока
                      хранения.</p>
                  </div>


                  <div data-locale="ru"></div>


                  <div data-locale="ru"></div>


                  <div className="one_line_col">
                    <h2 id="rights_subjects">Права Пользователей</h2>
                    <p>Пользователи могут осуществлять определенные права в отношении своих Данных, обрабатываемых
                      Владельцем.</p>
                    <p>В частности, Пользователи имеют право совершить следующие действия:</p>

                    <ul>
                      <li><strong>Отозвать свое согласие в любое время.</strong> Пользователи имеют право отозвать
                        согласие,
                        если
                        они ранее дали свое согласие на обработку своих Персональных данных.
                      </li>
                      <li><strong>Возразить против обработки своих данных.</strong> Пользователи имеют право возразить
                        против
                        обработки своих Данных, если обработка осуществляется на каком-либо правовом основании
                        помимо их
                        согласия. Более подробная информация по этому вопросу приведена в соответствующем разделе
                        ниже.
                      </li>
                      <li><strong>Получить доступ к своим Данным.</strong> Пользователи имеют право узнать,
                        обрабатываются
                        ли
                        данные Владельцем, получить информацию об определенных аспектах обработки и получить копию
                        обрабатываемых Данных.
                      </li>
                      <li><strong>Проверить Данные и потребовать исправления.</strong> Пользователи имеют право
                        проверить
                        точность
                        своих Данных и попросить их обновить или исправить.
                      </li>
                      <li><strong>Ограничить обработку своих Данных.</strong> При определенных обстоятельствах
                        Пользователи
                        имеют
                        право ограничить обработку своих Данных. В этом случае Владелец не будет обрабатывать их
                        Данные
                        ни в
                        каких целях, кроме их хранения.
                      </li>
                      <li><strong>Потребовать стереть их Персональные данные или удалить их иным образом.</strong> При
                        определенных обстоятельствах Пользователи имеют право добиться от Владельца удаления своих
                        Данных.
                      </li>
                      <li><strong>Получить свои Данные и передать их другому контролёру.</strong> Пользователи имеют
                        право
                        получить свои Данные в структурированном, широко используемом и машиночитаемом формате и,
                        если
                        это
                        технически возможно, беспрепятственно передать их другому контролёру. Это положение
                        применяется
                        при
                        условии, что Данные обрабатываются автоматическими средствами и что их обработка
                        осуществляется
                        на
                        основании согласия Пользователя или на основании договора, одной из сторон которого является
                        Пользователь, или на основании предшествующих заключению договора обязательств.
                      </li>
                      <li><strong>Подать жалобу.</strong> Пользователи имеют право предъявить претензию в компетентный
                        орган
                        по
                        защите данных.
                      </li>
                    </ul>

                    <h3>Сведения о праве на возражение против обработки Данных</h3>
                    <p>В тех случаях, когда Персональные данные обрабатываются в общественных интересах, при исполнении
                      официальных
                      полномочий, возложенных на Владельца, или в целях обеспечения законных интересов, преследуемых
                      Владельцем,
                      Пользователи могут возразить против такой обработки, представив в обоснование своего возражения
                      то
                      или
                      иное
                      основание, связанное с их конкретной ситуацией.</p>
                    <p>Вместе с тем, Пользователи должны знать, что, в случае обработки их Персональных данных в целях
                      осуществления
                      прямого маркетинга, Пользователи могут в любое время возразить против такой обработки без
                      представления
                      какого-либо обоснования. Пользователь может узнать, обрабатывает ли Владелец Персональные данные
                      в
                      целях
                      осуществления прямого маркетинга, ознакомившись с соответствующими разделами настоящего
                      документа.</p>

                    <h3>Порядок осуществления указанных прав</h3>
                    <p>Любые запросы, касающиеся осуществления прав Пользователя, можно направлять Владельцу, используя
                      контактные
                      данные, приведенные в настоящем документе. Эти запросы могут осуществляться бесплатно и будут
                      рассмотрены
                      Владельцем в кратчайший срок, не превышающий одного месяца.</p>
                  </div>


                  <div className="one_line_col">
                    <h2 id="further_data_processing_info">Дополнительная информация о сборе и обработке Данных</h2>
                    <h3>Судебные иски</h3>
                    <p>Персональные данные Пользователя могут быть использованы Владельцем в судебном процессе или на
                      этапах,
                      ведущих к возможному обращению в суд в связи с тем, что Приложение или сопутствующие Сервисы
                      использовались
                      ненадлежащим образом.<br/>
                      Пользователь заявляет о своей осведомленности о том, что Владелец может быть вынужден сообщить
                      Персональные
                      данные по требованию органов государственной власти.</p>
                    <h3>Дополнительная информация о Персональных данных Пользователя</h3>
                    <p>Помимо информации, содержащейся в настоящей политике приватности, Приложение может предоставлять
                      по
                      запросу
                      Пользователя дополнительную и контекстную информацию, касающуюся конкретных Сервисов или сбора и
                      обработки
                      Персональных данных.</p>
                    <h3>Системные журналы и техническое обслуживание</h3>
                    <p>В целях обеспечения работы системы и ее технического обслуживания Приложение и любые сторонние
                      сервисы
                      могут
                      собирать файлы, в которых регистрируется, как Приложение взаимодействует с Пользователями
                      (системные
                      журналы), или использовать с этой целью другие Персональные данные (в частности, IP-адрес).</p>
                    <h3>Информация, отсутствующая в настоящих правилах</h3>
                    <p>Дополнительные сведения, касающиеся сбора или обработки Персональных данных, можно в любое время
                      запросить у
                      Владельца. Смотрите контактные данные, указанные в начале настоящего документа.</p>

                    <h3>Как обрабатываются запросы «Не отслеживать»</h3>
                    <p>Приложение не поддерживает запросы «Не отслеживать». <br/>
                      Чтобы определить, поддерживает ли тот или иной используемый сторонний сервис запросы «Не
                      отслеживать»,
                      ознакомьтесь с соответствующими правилами обеспечения конфиденциальности персональных данных.
                    </p>

                    <h3>Внесение изменений в настоящие правила обеспечения конфиденциальности персональных данных</h3>
                    <p>Владелец оговаривает за собой право в любое время вносить изменения в настоящие правила
                      обеспечения
                      конфиденциальности персональных данных путем уведомления Пользователей на данной странице и,
                      возможно,
                      внутри данного Приложение и (или) — насколько это возможно с технической и юридической точек
                      зрения
                      —
                      путем
                      направления уведомления Пользователям с использованием любой контактной информации, доступной
                      Владельцу.
                      Настоятельно рекомендуется часто проверять данную страницу, сверяясь с датой последнего
                      изменения,
                      указанной
                      внизу.<br/><br/>В случае, если изменения повлияют на деятельность по обработке, осуществляемую
                      на
                      основании
                      согласия Пользователя, Владелец должен повторно получить согласие от Пользователя, если это
                      необходимо.
                    </p>

                  </div>


                  <div className="one_line_col">
                    <h2 id="california_info">Информация для калифорнийских потребителей</h2>
                    <p>Данная часть документа связана с информацией, содержащейся в остальных разделах политики
                      приватности,
                      дополняет ее и предоставляется компанией, которая эксплуатирует Приложение и, в соответствующих
                      случаях,
                      ее
                      материнской компанией, дочерними и аффилированными компаниями (для целей настоящей статьи
                      совместно
                      именуемыми «мы», «нам», «наш»).</p>
                    <p>Положения, содержащиеся в настоящей статье, применяются ко всем Пользователям, которые являются
                      потребителями, проживающими в штате Калифорния, Соединенные Штаты Америки, в соответствии с
                      «Законом
                      штата
                      Калифорния о защите персональных данных потребителей 2018 года» (Пользователи упоминаются ниже
                      как
                      «вы»,
                      «вам», «ваш»), и для таких потребителей настоящие положения заменяют любые другие положения в
                      настоящей
                      политике приватности, которые, возможно, от них отличаются или им противоречат.</p>
                    <p>В этой части документа используется термин «личная информация», как он определен в Законе штата
                      Калифорния о
                      защите персональных данных потребителей.</p>

                    <h3>Категории собираемой, раскрываемой или продаваемой личной информации</h3>
                    <p>В этом разделе кратко описываются категории личной информации, которую мы собирали, раскрывали
                      или
                      продавали,
                      а также цели совершения данных действий. <strong>Подробная информация об этих действиях
                        приведена в
                        разделе
                        «Подробная информация об обработке Персональных данных» настоящего документа.</strong></p>

                    <h4>Собираемая информация: категории собираемой нами личной информации</h4>
                    <p>Мы собрали следующие категории вашей личной информации: .</p>
                    <p>Мы не будем собирать дополнительные категории личной информации без вашего ведома.</p>

                    <h4>Как мы собираем информацию: из каких источников мы собираем личную информацию?</h4>
                    <p>Когда вами используется Приложение, мы прямо или косвенно получаем от вас вышеупомянутые
                      категории
                      личной
                      информации.</p>
                    <p>Например, вы непосредственно предоставляете свою личную информацию при отправке запросов с
                      помощью
                      любых
                      форм, которые предлагает Приложение. Кроме того, вы опосредованно предоставляете личную
                      информацию
                      при
                      навигации, поскольку Приложение автоматически отслеживает и собирает вашу личную информацию.
                      Наконец, мы
                      можем получать вашу личную информацию от третьих лиц, которые сотрудничают с нами в связи с
                      Сервисом
                      или
                      содействуют в том, чтобы Приложение и его функции работали.</p>

                    <h4>Как мы используем собранную информацию: передача и раскрытие вашей личной информации третьим
                      лицам в
                      деловых
                      целях</h4>
                    <p>Мы можем раскрывать собираемую о вас личную информацию третьему лицу в деловых целях.
                      В этом случае мы заключаем письменное соглашение с таким третьим лицом, требующее от получателя
                      обеспечивать
                      конфиденциальность личной информации и не использовать ее ни в каких целях, кроме тех, которые
                      необходимы
                      для исполнения соглашения.</p>
                    <p>Мы также можем раскрывать вашу личную информацию третьим лицам, если вы явным образом об этом
                      попросите
                      или
                      дадите нам соответствующее разрешение для того, чтобы предоставить вам наш Сервис.</p>
                    <p>Дополнительная информация о целях обработки приводится в соответствующем разделе настоящего
                      документа.</p>

                    <h4>Отсутствие продаж вашей личной информации</h4>
                    <p>В последние 12 месяцев мы не продавали никакую вашу личную информацию.</p>


                    <h4>В каких целях мы используем вашу личную информацию?</h4>
                    <p>Мы можем использовать вашу личную информацию для того, чтобы нормально работали Приложение и его
                      функции
                      («производственные цели»). В таких случаях ваша личная информация обрабатывается необходимым
                      образом
                      соразмерно производственным целям, в которых они собирались, и строго в рамках совместимых
                      оперативных
                      целей.</p>
                    <p>Мы также можем использовать вашу личную информацию по другим причинам, например, в коммерческих
                      целях
                      (как
                      указано в разделе «Подробная информация об обработке Персональных данных» настоящего документа),
                      а
                      также
                      в
                      целях соблюдения требований закона и защиты наших прав в компетентных органах в тех случаях,
                      когда
                      наши
                      права и интересы находятся под угрозой или мы несем реальные убытки.</p>
                    <p>Мы не будем использовать вашу личную информацию в других не связанных или несовместимых целях без
                      вашего
                      ведома.</p>

                    <h3>Ваши права на неприкосновенность частной жизни, действующие в Калифорнии, и порядок их
                      осуществления</h3>

                    <h4>Право на информацию и на переносимость</h4>
                    <p>Вы имеете право потребовать от нас раскрыть вам:</p>
                    <ul>
                      <li>категории и источники собираемой личной информации о вас, цели использования вашей
                        информации и
                        лиц,
                        которым такая информация предоставляется;
                      </li>
                      <li>
                        в случае продажи личной информации или ее раскрытия в производственных целях представить два
                        отдельных
                        списка с указанием:
                        <ul>
                          <li>в отношении продаж — категорий личной информации, приобретенных каждой категорией
                            получателей;
                            и
                          </li>
                          <li>в отношении раскрытия в производственных целях — категорий личной информации,
                            полученных
                            каждой
                            категорией получателей.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p>Описанное выше раскрытие будет ограничено личной информацией, собранной или использованной за
                      последние
                      12
                      месяцев.</p>
                    <p>Если мы представим ответ в электронном виде, прилагаемая информация будет «переносимой», т. е.
                      предоставляемой в формате, обеспечивающем простоту использования, с тем чтобы вы могли
                      беспрепятственно
                      передать информацию другой организации (при условии, что это технически осуществимо).</p>

                    <h4>Право потребовать удаления вашей личной информации</h4>
                    <p>Вы вправе потребовать, чтобы мы удалили любую вашу личную информацию, за исключением
                      предусмотренных
                      законом
                      случаев (включая, помимо прочего, случаи использования информация для выявления и исправления
                      ошибок,
                      которые содержит Приложение, выявления инцидентов в системе безопасности и защиты от
                      мошеннической
                      или
                      незаконной деятельности, осуществления определенных прав и т. д.).</p>
                    <p>Если предусмотренное законом исключение не применяется, в результате осуществления вашего права
                      мы
                      удалим
                      вашу личную информацию и дадим указание любому из наших поставщиков услуг сделать то же
                      самое.</p>

                    <h4>Порядок осуществления ваших прав</h4>
                    <p>Для осуществления вышеописанных прав необходимо направить нам запрос, верифицирующийся как
                      исходящий
                      от
                      вас,
                      воспользовавшись контактными данными, указанными в настоящем документе.</p>
                    <p>Для того, чтобы мы могли ответить на ваш запрос, нам необходимо знать, кто вы. В связи с этим вы
                      можете
                      осуществить вышеуказанные права только путем подачи поддающегося проверке запроса, в котором
                      обязательно:</p>
                    <ul>
                      <li>содержится достаточно информации для обоснованно необходимого подтверждения того, что вы
                        являетесь
                        лицом, о котором мы собирали личную информацию, или уполномоченным представителем;
                      </li>
                      <li>описывается ваш запрос с необходимой подробностью, с тем чтобы мы могли его правильно понять
                        и
                        оценить,
                        и отреагировать на него.
                      </li>
                    </ul>
                    <p>Мы не будем отвечать на запрос, если не сможем подтвердить вашу личность и, следовательно,
                      подтвердить,
                      что
                      имеющаяся в нашем распоряжении личная информация действительно касается вас.</p>
                    <p>Если вы не можете лично подать поддающийся проверке запрос, вы можете уполномочить лицо,
                      зарегистрированное
                      заведующим канцелярией штата Калифорния, действовать от вашего имени.</p>
                    <p>Если вы совершеннолетний, вы можете подать поддающийся проверке запрос от имени
                      несовершеннолетнего,
                      находящегося под вашим родительским контролем.</p>
                    <p>Вы можете отправить не более 2 запросов в течение 12 месяцев.</p>

                    <h4>Каким образом и в какой срок мы должны обработать ваш запрос</h4>
                    <p>Мы подтвердим получение вашего поддающегося проверке запроса в течение 10 дней и представим
                      информацию о
                      том,
                      как образом он будет обрабатываться.</p>
                    <p>Мы ответим на ваш запрос в течение 45 дней с момента его получения. Если нам понадобится больше
                      времени,
                      мы
                      объясним вам причины и сообщим, сколько времени нам нужно. В связи с этим обратите внимание, что
                      для
                      выполнения вашего запроса может потребоваться до 90 дней.</p>
                    <p>Предоставляемая информация будет охватывать предыдущий 12-месячный период.</p>
                    <p>В случае отклонения запроса мы объясним вам причины отказа.</p>
                    <p>Мы не взимаем плату за обработку или реагирование на ваш поддающийся проверке запрос, за
                      исключением
                      случаев,
                      когда запрос является явно необоснованным или чрезмерным. В указанных случаях мы можем взыскать
                      умеренную
                      плату или отказаться от выполнения запроса. В любом случае мы сообщим о своем решении и объясним
                      причины, по
                      которым оно было принято.</p>


                  </div>


                  <div className="one_line_col">
                    <h2 id="brazil_info">Информация для Пользователей, проживающих в Бразилии</h2>
                    <p>Эта часть документа объединена и дополняет информацию, содержащуюся в остальной части политики
                      приватности и
                      предоставленной компанией, запустившей Приложение и, если применимо, ее головной, дочерней и
                      аффилированной
                      компанией (для целей раздела именуемыми совместно как «мы», «нас», «наш»). <br/> Положения,
                      содержащиеся
                      в
                      этом разделе, применяются ко всем Пользователям, проживающим в Бразилии, в соответствии с актом
                      «Lei
                      Geral
                      de Proteção de Dados» (Пользователи далее как «ты», «твой», «твои»). Для таких Пользователей
                      настоящие
                      положения имеют большую юридическую силу чем положения отличающиеся или противоречащие
                      положениям
                      политики
                      приватности. <br/>Эта часть документа использует термин «личная информация» как он определен в
                      Lei
                      Geral
                      de
                      Proteção de Dados (<strong>LGPD</strong>).</p>

                    <h3>Основания, на которых мы обрабатываем вашу личную информацию</h3>
                    <p>Мы можем обрабатывать вашу личную информацию исключительно в случае если у нас есть правовые
                      основания
                      для
                      такой обработки. Правовыми основаниями являются следующие:
                    </p>
                    <ul>
                      <li>ваше согласие на соответствующие действия по обработке;</li>
                      <li>соблюдение распространяющегося на нас законодательного или регуляторного обязательства;</li>
                      <li>соблюдение публичных политик, предусмотренных в контрактах, соглашениях и иных юридических
                        инструментах;
                      </li>
                      <li>исследования, проводимые исследовательскими организациями, преимущественно на основе
                        анонимизированной
                        личной информации;
                      </li>
                      <li>осуществление процедур, предусмотренных контрактом, а также предшествующих его заключению в
                        случае
                        когда
                        вы сторона данного контракта;
                      </li>
                      <li>осуществление прав по судебному, административному или арбитражному процессу;</li>
                      <li>защита или физическая безопасность вас или третьего лица;</li>
                      <li>защита здоровья – во время процедур, осуществляемых организациями здравоохранения;</li>
                      <li>наши законные интересы, при условии что ваши фундаментальные права и свободы не имеют
                        большего
                        значения,
                        чем такие интересы; и
                      </li>
                      <li>кредитная гарантия.</li>
                    </ul>

                    <p>Для того чтобы узнать больше о правовых основаниях, вы можете связаться с нами в любое время по
                      контактным
                      данным, указанным в настоящем документе. </p>

                    <h3>Категории обрабатываемых личной информации</h3>
                    <p>Для того чтобы узнать, какие категории личной информации обрабатываются, вы можете ознакомиться с
                      разделом
                      «Подробная информация об обработке Персональных данных» настоящего документа. </p>

                    <h3>Почему мы обрабатываем вашу личную информацию</h3>
                    <p>Для того чтобы узнать, почему мы обрабатываем вашу личную информацию, вы можете ознакомиться с
                      разделом
                      «Подробная информация об обработке Персональных Данных» и «Цели обработки» настоящего
                      документа.</p>

                    <h3>Ваши права на защиту приватности в Бразилии, информация о том, как подать запрос и наш ответ на
                      ваши
                      запросы</h3>
                    <h4>Ваши права на защиту приватности в Бразилии</h4>

                    <p> Вы имеете право на:</p>
                    <ul>
                      <li>получение подтверждения факта обработки вашей личной информации;</li>
                      <li>доступ к вашей личной информации;</li>
                      <li>исправление неполной, неточной или устаревшей личной информации;</li>
                      <li>анонимизацию, блокировку или исключение вашей ненужной или чрезмерной личной информации,
                        которая
                        не
                        обрабатывалась в соответствии с LGPD;
                      </li>
                      <li>получение информации о возможности предоставления или отзыва вашего согласия и последствия
                        этого;
                      </li>
                      <li>получение информации о третьих лицах, с которыми мы делимсяличной информацией;</li>
                      <li>передачу, по вашей явно выраженной просьбе, вашей личной информации (за исключением
                        анонимизированной
                        информации) другому сервису или провайдеру продукта, при условии, что защищена наша
                        коммерческая
                        и
                        промышленная тайна;
                      </li>
                      <li>удаление вашей личной информации, обработка которой осуществляется, если обработка
                        осуществляется по
                        вашему согласию и если только не применяется одно или более исключений, предусмотренных ст.
                        16
                        LGPD;
                      </li>
                      <li>отзыв вашего согласия в любое время;</li>
                      <li>подачу жалобы, связанной с вашей личной информацией через ANPD (Национальное Управление по
                        Защите
                        Персональных Данных) или организации по защите прав потребителей;
                      </li>
                      <li>возражения против действий по обработке в случаях, когда обработка осуществляется не в
                        соответствии
                        с
                        положениями закона;
                      </li>
                      <li>требование о предоставлении ясной и достаточной информации в отношении критериев и процедур,
                        используемых для автоматически принимаемого решения; и
                      </li>
                      <li>требование о пересмотре решений, принятых исключительно на основе автоматической обработки
                        вашей
                        личной
                        информации и влияющих на ваши интересы. К ним относятся решения по определению вашего
                        личного,
                        профессионального, потребительского и кредитного профиля или проявлений вашей личности.
                      </li>
                    </ul>

                    <p> Если вы воспользуетесь своими правами, вы никогда не подвергнетесь дискриминации и не
                      пострадаете
                      иным
                      образом.</p>

                    <h4>Как подать требование</h4>
                    <p>Для осуществления своих прав Вы можете подать явно выраженный запрос без внесения какой-либо
                      платы, в
                      любое
                      время, с использованием контактной информации, указанной в настоящем документе, или через вашего
                      законного
                      представителя.</p>

                    <h4>Как и когда мы ответим на ваш запрос</h4>
                    <p>Мы будем прилагать все возможные усилия для своевременного реагирования на ваши запросы. <br/>
                      В любом случае, если мы не сможем этого сделать, мы проследим за тем, чтобы вам сообщили
                      фактические
                      или
                      юридические причины, которые препятствуют тому, чтобы мы незамедлительно или когда-либо в
                      принципе
                      исполнили
                      ваш запрос. В случаях, когда мы не обрабатываем вашу личную информацию, то, если у нас будет
                      такая
                      возможность, мы проинформируем вас о физическом или юридическом лице, которому вы должны
                      адресовать
                      свой
                      запрос.</p>
                    <p>В случае если вы подаете запрос о <strong>доступе</strong> или о <strong>подтверждении факта
                      обработки</strong> персональной информации, пожалуйста, удостоверьтесь, что вы указали, в каком
                      виде
                      должна
                      быть предоставлена ваша персональная информация: в электронной или печатной. Вы также должны
                      проинформировать нас, хотите ли вы получить ответ на запрос немедленно, в таком случае мы
                      ответим
                      вам в
                      сокращенном виде, либо о том, что вместо этого вам нужно полное раскрытие информации. В
                      последнем
                      случае
                      мы
                      ответим в течение 15 дней с даты подачи вашего запроса и предоставим вам всю информацию об
                      источнике
                      вашей
                      персональной информации, подтверждение того, существуют или нет ее записи, о любых критериях,
                      использованных
                      для обработки, и цели обработки, сохраняя при этом нашу коммерческую и промышленную тайну.</p>
                    <p>В случае если вы подаете запрос об <strong>исправлении, удалении, анонимизации или блокировке
                      персональной
                      информации</strong>, мы обязательно немедленно передадим ваш запрос другим сторонам, с которыми
                      мы
                      делились
                      вашей персональной информацией, для того чтобы предоставить таким третьим лицам возможность
                      исполнить
                      ваш
                      запрос – за исключением случаев когда такая коммуникация однозначно невозможна или влечет за
                      собой
                      непропорциональные усилия с нашей стороны. </p>

                    <h3>Передача личной информации за пределами Бразилии, разрешаемая законодательством </h3>
                    <p>Нам разрешено передавать вашу личную информацию за пределы территории Бразилии в следующих
                      случаях:</p>
                    <ul>
                      <li>когда передача необходима для международного правового сотрудничества между государственной
                        разведкой,
                        органами расследования и преследования, в соответствии с юридическими средствами,
                        предоставленными
                        международным правом;
                      </li>
                      <li>когда передача необходима для защиты жизни или физической безопасности вас или третьих
                        лиц;
                      </li>
                      <li>когда передача разрешена ANPD;</li>
                      <li>когда передача происходит из обязательства из соглашения о международной кооперации;</li>
                      <li>когда передача необходима для исполнения публичной политики или юридических аспектов
                        исполнения
                        публичной функции;
                      </li>
                      <li>когда передача необходима для соблюдения юридического или регуляторного обязательства,
                        исполнения
                        контракта или процедур, связанных с его заключением, или правильного применения юридических,
                        административных или арбитражных процедур.
                      </li>
                    </ul>
                  </div>


                  <div className="one_line_col">
                    <div className="box_primary box_10 definitions expand">
                      <h3 id="definitions_and_legal_references" className="expand-click w_icon_24 icon_ribbon">
                        Определения терминов и ссылки на правовые нормы
                      </h3>
                      <div className="expand-content">
                        <h4>Персональные данные (или Данные)</h4>
                        <p>Любая информация, которая прямо, косвенно или в связи с другой информацией – в том числе.
                          персональным идентификационным номером – обеспечивает возможность выявления или
                          узнаваемость
                          физического лица.</p>

                        <h4>Данные об использовании (сетевых ресурсов)</h4>
                        <p>Информация, которую автоматически собирают Приложение или сторонние сервисы, которые
                          использует
                          Приложение), в частности: IP-адреса или доменные имена компьютеров Пользователей,
                          которые
                          используют
                          Приложение, URI-адреса (универсальные коды ресурсов), время обращения с запросом, способ
                          обращения с
                          запросом к серверу, размер файла, полученного в ответ, числовой код, указывающий на
                          статус
                          ответа
                          сервера (удачный исход, ошибка и т.д.), страна происхождения, характеристики браузера и
                          операционной
                          системы, используемых Пользователем, различные сведения о времени каждого посещения
                          (например,
                          время, проведенное на каждой странице Приложения), и сведения о перемещениях внутри
                          Приложения с
                          отдельным указанием очередности посещения страниц, а также другие параметры операционной
                          системы
                          устройства и (или) вычислительной и информационной среде Пользователя.</p>

                        <h4>Пользователь</h4>
                        <p>Отдельное лицо, использующее Приложение, которое, если не указано иное, совпадает с
                          Субъектом
                          данных.</p>

                        <h4>Субъект данных</h4>
                        <p>Физическое лицо, к которому относятся Персональные данные.</p>

                        <h4>Обработчик данных (или Администратор данных)</h4>
                        <p>Физическое или юридическое лицо, официальный государственный орган, агентство или иной
                          орган,
                          которые
                          обрабатывают персональные данные по поручению Контролера.</p>

                        <h4>Контролер (или Владелец) данных
                        </h4>
                        <p>Физическое или юридическое лицо, государственный орган, ведомство или иное лицо, которое
                          самостоятельно или совместно с другими лицами определяет предназначение и средства
                          обработки
                          Персональных данных, включая меры безопасности, касающиеся того, как эксплуатируется и
                          используется
                          Приложение. Если не указано иное, Контролером данных является Владелец, т. е. лицо,
                          которому
                          принадлежит Приложение.</p>

                        <h4>Приложение</h4>
                        <p>Средство, с помощью которого осуществляются сбор и обработка Персональных данных
                          Пользователя.</p>

                        <h4>Услуга (или Сервис)</h4>
                        <p>Услуга, которую предоставляет Приложение, описанная в соответствующих условиях (если
                          таковые
                          имеются), а также на этом сайте или в этом приложении.</p>

                        <h4>Европейский союз (или ЕС)</h4>
                        <p>Если не указано иное, все встречающиеся в настоящем документе ссылки на Европейский союз
                          включают
                          все
                          существующие в настоящее время страны-члены Европейского союза и Европейской
                          экономической
                          зоны.</p>

                        <hr/>
                        <h4>Правовая информация</h4>
                        <p>Настоящее заявление о конфиденциальности подготовлено на основе положений различных
                          законодательных
                          актов, в том числе ст. 13/14 Постановления (ЕС) 2016/679 («Общие положения о защите
                          персональных
                          данных»).</p>
                        <p>Если в настоящем документе не указано иное, то действие правил обеспечения
                          конфиденциальности
                          персональных данных распространяется исключительно на Приложение.</p>
                      </div>
                    </div>
                  </div>

                  <div className="one_line_col">
                    <h2 id="owner_of_the_data">
                      Owner and Data Controller
                    </h2>
                    <p>Alexey Ursul, Russia, Novosibirsk, Shaturskaya 8, 134 (zip code: 630055)</p>
                    <p><strong>Owner contact email:</strong> alex.ursul@gmail.com</p>
                  </div>

                  <div className="iub_footer">
                    <p>
                      Latest update: April 23, 2021
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </BasicPage>
    )
  }
}

export default PrivacyPolicyRu