import axios from 'axios'

export async function fetchPremiumExpires(uid) {
  const response = await axios.get('https://tusk.best/api/subscription/info?uuid=' + uid.toString())
  if (response.data !== null) {
    return response.data.date ?? '2000-01-01'
  }

  return '2000-01-01'
}
