import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAsbmDKh7OZaf7BXzCORc25sqbmqWVsFxc',
  authDomain: 'tusk-8cb63.firebaseapp.com',
  databaseURL: 'https://tusk-8cb63.firebaseio.com',
  projectId: 'tusk-8cb63',
  storageBucket: 'tusk-8cb63.appspot.com',
  messagingSenderId: '563395237569',
  appId: '1:563395237569:web:075234322f30d2219ca640',
  measurementId: 'G-CE9D8VYWDR'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const googleProvider = new GoogleAuthProvider()
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    console.log(user)
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    return err.code
  }

  return null
}

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    console.log(user)
  } catch (err) {
    console.error(err)
    return err.message
  }

  return null
}

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (err) {
    console.error(err)
    alert(err.message)
  }

  return false
}

const logout = () => {
  signOut(auth)
}

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
}