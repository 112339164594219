import React from 'react'
import { getI18n, withTranslation } from 'react-i18next'
import TermsAndConditionsRu from './TermsAndConditionsRu'
import TermsAndConditionsEn from './TermsAndConditionsEn'

class TermsAndConditions extends React.Component {
  render() {
    const lang = getI18n().resolvedLanguage
    const {t} = this.props

    if (lang === 'ru') {
      return <TermsAndConditionsRu title={ t('terms_and_conditions') }/>
    }

    return <TermsAndConditionsEn title={ t('terms_and_conditions') }/>
  }
}

export default withTranslation()(TermsAndConditions)