import React from 'react'
import tuskSvg from '../../assets/images/tusk.svg'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import { withTranslation } from 'react-i18next'
import androidIcon from '../../assets/images/android_icon.png'
import appleIcon from '../../assets/images/apple_icon.png'
import windowsIcon from '../../assets/images/windows_icon.png'
import phone1Image from '../../assets/images/phone1.png'
import phone2Image from '../../assets/images/phone2.png'
import { ExternalLink } from 'react-feather'
import { Link } from 'react-router-dom'
import DataProvider from '../../services/DataProvider'

class Home extends React.Component {
  render() {
    const {t} = this.props
    return (
      <BasicPage title={t('home')}>
        <div className="container">
          <div className="row" id="screen1">
            <div className="column-left col-md-7 col-lg-7 col-12">
              <img src={ tuskSvg } alt="Tusk logo" id="logo"/>
              <h1>Tusk</h1>
              <h3>{ t('tusk_slogan') }</h3>
              <div className="d-flex mt-4 menu">
                <a href="https://play.google.com/store/apps/details?id=com.ulsoft.tusk" target="_blank" rel="noreferrer"
                   className="d-inline-flex flex-column align-content-start align-items-center">
                  <img src={ androidIcon } alt={ t('android_version') } height="64" width="64" className="mb-2"/>
                  <div>
                    { t('download_for_android') }&nbsp;<ExternalLink size={ 12 } className="mb-2"/>
                  </div>
                </a>
                <a href="https://apps.apple.com/bm/app/tusk-flexible-task-manager/id1586005127" target="_blank"
                   rel="noreferrer"
                   className="d-inline-flex flex-column align-content-start align-items-center">
                  <img src={ appleIcon } alt={ t('ios_version') } height="64" width="64" className="mb-2"/>
                  <div>
                    { t('download_for_ios') }&nbsp;<ExternalLink size={ 12 } className="mb-2"/>
                  </div>
                </a>
                <a href={ DataProvider.linkDownloadWindows } target="_blank" rel="noreferrer"
                   className="d-inline-flex flex-column align-content-start align-items-center">
                  <img src={ windowsIcon } alt={ t('windows_version') } height="64" width="64" className="mb-2"/>
                  <div>
                    { t('download_for_windows') } (v1.1.100, { t('beta') })
                  </div>
                </a>
              </div>
              <div className="mb-4 mt-3 text-start">
                <ul>
                  <li>
                    <Link to="/privacy_policy">{ t('privacy_policy') }</Link>
                  </li>
                  <li>
                    <Link to="/terms_and_conditions">{ t('terms_and_conditions') }</Link>
                  </li>
                </ul>
              </div>
              <div className="d-block text-start pt-2">
                <p>{ t('tusk_description1') }</p>
                <p>{ t('tusk_description2') }</p>
              </div>
            </div>
            <div className="column-right col-md-4 col-lg-5 col-12 text-lg-center text-md-right text-center">
              <img src={ phone1Image } height="630" alt={ t('screenshot_of_tusk') }/>
            </div>
          </div>
          <div className="row" id="screen2">
            <div className="column-left col-md-6 col-lg-5 col-xl-4 col-12 text-center text-md-left order-2 order-md-1">
              <img src={ phone2Image } height="630" className="img-responsive" alt={ t('screenshot_of_tusk') + ' 2' }/>
            </div>
            <div className="column-right col-md-6 col-lg-7 col-xl-8 col-12 order-1 order-md-2 mb-4 mb-md-0">
              <h2>{ t('why_use_tusk') }</h2>

              <h4>{ t('flexibility') }</h4>
              <p>{ t('flexibility_description') }</p>

              <h4>{ t('simplicity') }</h4>
              <p>{ t('simplicity_description') }</p>

              <h4>{ t('customization') }</h4>
              <p>{ t('customization_description') }</p>

              <h4>{ t('ready_templates') }</h4>
              <p>{ t('ready_templates_description') }</p>

              <h4>{ t('reminders') }</h4>
              <p>{ t('reminders_description') }</p>

              <h4>{ t('its_free') }</h4>
              <p>{ t('its_free_description') }</p>

              <hr/>
              <p>{ t('become_more_productive') }</p>
            </div>
          </div>
        </div>
      </BasicPage>
    )
  }
}

export default withTranslation()(Home)