import React, {useEffect} from 'react'
import {useAuthState} from 'react-firebase-hooks/auth'
import {useNavigate} from 'react-router-dom'
import {
    auth,
} from '../../services/firebase'
import './Account.scss'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import {getI18n, Trans, useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Container, Row} from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import Moment from 'react-moment'
import GetPremium from '../../components/GetPremium/GetPremium'
import {fetchExpiresAsync, selectExpires, selectExpiresLoadingStatus} from './accountSlice'

function Account() {
    const [user] = useAuthState(auth)
    const premiumExpires = useSelector(selectExpires)
    const expiresLoadingStatus = useSelector(selectExpiresLoadingStatus)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const lang = getI18n().resolvedLanguage
    moment.locale(lang)
    const dispatch = useDispatch()
    let firstLoad = true

    useEffect(() => {
        if (user === null) {
            setTimeout(() => {
                if (auth.currentUser === null) {
                    navigate('/')
                }
            }, 2000)
            return
        }

        if (firstLoad && premiumExpires === null && expiresLoadingStatus === 'idle') {
            firstLoad = false
            dispatch(fetchExpiresAsync(user.uid))
        }
    }, [user])

    let premiumInfo = (
        <p>{t('data_loading')}</p>
    )
    if (expiresLoadingStatus === 'idle' && premiumExpires != null) {
        let expires = moment(premiumExpires)
        if (expires.isSameOrBefore(moment('2000-01-01'))) {
            premiumInfo = (
                <div>
                    <p>{t('premium_is_not_activated')}</p>
                    <GetPremium loading={expiresLoadingStatus !== 'idle'}
                                onRefresh={() => dispatch(fetchExpiresAsync(user.uid))}/>
                </div>
            )
        } else if (moment().isAfter(expires)) {
            premiumInfo = (
                <div>
                    <p className="fw-bold">{t('premium_expired')} <Moment format="L" locale={lang}>{expires}</Moment>
                    </p>
                    <GetPremium loading={expiresLoadingStatus !== 'idle'}
                                onRefresh={() => dispatch(fetchExpiresAsync(user.uid))}/>
                </div>
            )
        } else {
            if (expires.isSameOrAfter(moment('2030-01-01'))) {
                premiumInfo = (
                    <p><b>{t('lifetime_premium_activated')}</b></p>
                )
            } else {
                premiumInfo = (
                    <div>
                        <p><b>{t('premium_is_active_until')} <Moment format="L" locale={lang}>{expires}</Moment></b></p>
                        <br />
                        <GetPremium loading={expiresLoadingStatus !== 'idle'}
                                    onRefresh={() => dispatch(fetchExpiresAsync(user.uid))}/>
                    </div>
                )
            }

        }
    }

    return (
        <BasicPage title={t('account_details')}>
            <div className="account">
                <Container>
                    <Row>
                        <Col xs={0} md={1} lg={2} xl={3}></Col>
                        <Col>
                            <Container className="account__details text-start">
                                <Row>
                                    <Col>
                                        <h2>{t('account_details')}</h2>
                                        <p>{user?.email}</p>
                                        {premiumInfo}
                                        <hr className="my-4"/>
                                        <p>
                                            <i>
                                                <Trans i18nKey="if_you_have_questions">
                                                    If you have questions about the premium or the application, please
                                                    contact us at <a
                                                    href="mailto:support@tusk.best">support@tusk.best</a>.
                                                </Trans>
                                            </i>
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={0} md={1} lg={2} xl={3}></Col>
                    </Row>
                </Container>
            </div>
        </BasicPage>
    )
}

export default Account