import React from 'react'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import { Col, Container, Row } from 'react-bootstrap'

class TermsAndConditionsRu extends React.Component {
  render() {
    return (
      <BasicPage title={ this.props.title }>
        <Container>
          <Row>
            <Col>
              <div className="pt-2 pt-md-5" id="privacy-policy">
                <div id="wbars">
                  <div className="one_line_col">
                    <h1>Пользовательское соглашение</h1>
                    <p>Настоящее пользовательское соглашение (далее - <strong>«Соглашение»</strong>) является
                      документом,
                      представляющим собой публичную оферту в соответствии со статьей 437 Гражданского кодекса
                      Российской
                      Федерации, и содержит правила и условия предоставления и использования услуг сайта Tusk
                      (<a href="https://tusk.best">https://tusk.best</a>) (далее - <strong>«Сайт»</strong>) и связанного
                      с ним кроссплатформенного приложения Tusk (далее
                      - <strong>«Сервис»</strong>) - приложения для тайм-менеджмента.</p>
                  </div>
                  <div className="one_line_col">
                    <h2>1. Статус Соглашения</h2>
                    <ol>
                      <li>Настоящее Соглашение, а также Приложения к нему, являющиеся неотъемлемой часть Соглашения,
                        разработано Администрацией Сервиса и определяет правила и условия предоставления и использования
                        услуг
                        Сервиса, а также права и обязанности Пользователей и Администрации Сервиса.
                      </li>

                      <li>Настоящее Соглашение является юридически обязательным соглашением между Пользователем и
                        Администрацией Сервиса, предметом которого является предоставление Администрацией Сервиса
                        Пользователю
                        услуг по использованию Сервиса (далее – <strong>«Услуги»</strong>), содержащее правила и условия
                        предоставления и
                        использования Услуг. Помимо настоящего Соглашения, к соглашению между Пользователем и
                        Администрацией
                        Сервиса относятся все специальные документы, регулирующие предоставление отдельных услуг Сервиса
                        и
                        размещенные в соответствующих разделах Сервиса в сети Интернет.
                      </li>

                      <li>Пользователь обязан полностью ознакомиться с настоящим Соглашением до момента регистрации на
                        Сервисе. Регистрация Пользователя на Сервисе означает полное и безоговорочное принятие
                        Пользователем
                        настоящего Соглашения в соответствии со статьей 438 Гражданского кодекса Российской Федерации.
                      </li>

                      <li>Настоящее Соглашение может быть изменено и/или дополнено Администрацией Сервиса в
                        одностороннем порядке без какого-либо специального уведомления. Настоящее Соглашение является
                        открытым
                        и общедоступным документом. Действующая редакция Соглашения располагается в сети Интернет по
                        адресу:
                        <a href="https://tusk.best/terms_and_conditions">https://tusk.best/terms_and_conditions</a>.
                        Администрация Сервиса рекомендует Пользователям
                        регулярно
                        проверять положения настоящего Соглашения на предмет их изменения и/или дополнения. Продолжение
                        использования Сайта Пользователем после внесения изменений и/или дополнений в настоящее
                        Соглашение
                        означает принятие и согласие Пользователя с такими изменениями и/или дополнениями. Пользователь
                        вправе
                        отказаться от принятия вышеуказанных изменений и/или дополнений, производимых Администрацией
                        Сервиса,
                        что означает отказ Пользователем от Услуг.
                      </li>
                    </ol>
                  </div>

                  <div className="one_line_col">
                    <h2>2. Статус Сервиса</h2>
                    <ol>
                      <li>Сервис является интернет-ресурсом и представляет собой совокупность информации и программ для
                        ЭВМ, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети
                        Интернет по сетевому адресу: <a href="https://tusk.best">https://tusk.best</a>, а также
                        связанного с сайтом кроссплатформенного
                        приложения Tusk, доступного для скачивания через сайты Google Play и Apple App Store (ссылки
                        доступны
                        на главной странице Сайта).
                      </li>

                      <li>Все права на Сервис в целом и на использование сетевого адреса (доменного имени)
                        <a href="https://tusk.best">https://tusk.best</a> принадлежат Администрации Сайта. Последняя
                        предоставляет доступ к Сервис всем
                        заинтересованным лицам в соответствии с настоящим Соглашением и действующим законодательством
                        Российской Федерации.
                      </li>
                    </ol>
                  </div>


                  <div className="one_line_col">
                    <h2>3. Администрация Сервиса</h2>
                    <ol>
                      <li>Под Администрацией Сервиса (ранее и далее – <strong>«Администрация Сервиса»</strong>) в
                        настоящем
                        Соглашении и иных специальных документах, размещенных на Сайте, понимается Индивидуальный
                        предприниматель Урсул Алексей Сергеевич, ОГРНИП 321547600083721, ИНН 540862460020,
                        зарегистрированный по адресу: 630055, г. Новосибирск,
                        ул.
                        Шатурская 8 - 134.
                      </li>

                      <li>Обращения, предложения и претензии физических и юридических лиц к Администрации Сервиса в
                        связи с настоящим Соглашением и всеми вопросами по функционированию Сервиса, нарушениями прав и
                        интересов третьих лиц при его использовании, а также для запросов уполномоченных
                        законодательством
                        Российской Федерации лиц могут быть направлены на почтовый адрес: 630055, г. Новосибирск, ул.
                        Шатурская 8 - 134, ИП Урсул Алексей Сергеевич, телефон +799946668824.
                      </li>

                      <li>Отношения Сторон могут быть дополнительно урегулированы отдельными документами и
                        соглашениями, регламентирующими использование соответствующего Сервиса Администрации и/или
                        оказание
                        услуг Пользователю. Применение таких дополнительных документов и соглашений не отменяет действие
                        настоящего Регламента.
                      </li>

                      <li>Никакие положения настоящего Соглашения не предоставляют Пользователю право на использование
                        фирменного наименования, товарных знаков, доменных имен и иных отличительных знаков
                        Администрации
                        Сервиса. Право на использование фирменного наименования, товарных знаков, доменных имен и иных
                        отличительных знаков Администрации Сервиса может быть предоставлено исключительно по письменному
                        соглашению с Администрацией Сервиса.
                      </li>
                    </ol>
                  </div>

                  <div className="one_line_col">
                    <h2>4. Регистрация на Сайте и статус Пользователя</h2>
                    <ol>
                      <li>Регистрация Пользователя на Сайте является бесплатной, добровольной и производится в сети
                        Интернет по сетевому адресу: <a href="https://tusk.best/register">https://tusk.best/register</a>.
                        Регистрация и авторизация на Сайте
                        осуществляется с помощью подключенных социальных сетей, на базе протокола OpenID или OAuth в
                        соответствии с настройками доступа к данным Пользователя в социальной сети.
                      </li>

                      <li>Пользователем Сервиса является физическое лицо, достигшее возраста, допустимого в
                        соответствии с законодательством Российской Федерации для акцепта настоящего Соглашения и
                        обладающее
                        соответствующими полномочиями, а также юридические лица, зарегистрированные на Сайте в
                        соответствии с
                        установленным настоящим Соглашением порядком (ранее и далее – <strong>«Пользователь»</strong>).
                      </li>

                      <li>При регистрации на Сайте Пользователь обязан предоставить Администрации Сервиса необходимую
                        достоверную, актуальную и полную информацию, включая уникальные для каждого Пользователя логин
                        (адрес
                        электронной почты для входа на Сайт) и пароль доступа к Сайту, а также фамилию и имя.
                        Регистрационная
                        форма Сайта и/или Администрация Сервиса может запрашивать у Пользователя дополнительную
                        информацию,
                        которую пользователь обязан предоставить. Если Пользователь предоставляет неверную информацию
                        или у
                        Администрации Сайта есть основания полагать, что предоставленная Пользователем информация
                        неполна или
                        недостоверна, то Администрация Сервиса имеет право по своему усмотрению заблокировать или
                        удалить
                        Официальную страницу Пользователя и отказать Пользователю в предоставлении Услуг.
                      </li>

                      <li>Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие
                        законодательству Российской Федерации предоставленной при регистрации информации и ее чистоту от
                        претензий третьих лиц. Администрация Сервиса оставляет за собой право в любой момент потребовать
                        от
                        Пользователя подтверждения данных, указанных при регистрации, и запросить в связи с этим
                        подтверждающие документы, непредставление которых, по усмотрению Администрации Сайта, может быть
                        приравнено к предоставлению недостоверной информации и повлечь последствия, предусмотренные
                        пунктом
                      </li>
                      <li>
                        После предоставления информации, указанной в пункте 4.3 настоящего Соглашения, Пользователю
                        необходимо подтвердить свою регистрацию путем активации Официальной страницы через сообщение,
                        отправленное Администрацией Сайта на электронную почту Пользователя.
                      </li>
                      <li>
                        При регистрации Пользователь соглашается с настоящим Соглашением и принимает на себя
                        указанные в нем права и обязанности, связанные с использованием и функционированием Сайта.
                      </li>
                      <li>
                        После успешной регистрации Пользователя на Сайте Администрация принимает на себя права и
                        обязанности перед Пользователем, указанные в настоящем Соглашении.
                      </li>
                      <li>
                        Обработка данных и политика Tusk в отношении обработки персональных и конфиденциальных данных
                        осуществляется в соответствии с GDPR. Дополнительная информация и использование данных
                        Пользователя
                        размещены в Политике конфиденциальности, которая находится в соответствующем разделе сайта.
                      </li>
                      <li>
                        Принимая настоящее Соглашение путем регистрации на Сайте, Пользователь подтверждает свое
                        согласие на обработку Администрацией его персональных данных, предоставленных при регистрации, а
                        также
                        размещаемых Пользователем добровольно на своей Официальной странице. Обработка персональных
                        данных
                        Пользователя осуществляется в соответствии с законодательством Российской Федерации.
                        Администрация
                        Сервиса обрабатывает персональные данные Пользователя в целях предоставления Пользователю услуг;
                        проверки, исследования и анализа таких данных, позволяющих поддерживать и улучшать сервисы и
                        разделы
                        Сайта, а также разрабатывать новые сервисы и разделы Сайта. Администрация Сервиса принимает все
                        необходимые меры для защиты персональных данных Пользователя от неправомерного доступа,
                        изменения,
                        раскрытия или уничтожения. Администрация предоставляет доступ к персональным данным Пользователя
                        только тем работникам, подрядчикам и агентам Администрации Сайта, которым эта информация
                        необходима
                        для обеспечения функционирования Сайта и предоставления Услуг Пользователю. Администрация
                        Сервиса
                        вправе использовать предоставленную Пользователем информацию, в том числе персональные данные, в
                        целях
                        обеспечения соблюдения требований действующего законодательства Российской Федерации (в том
                        числе в
                        целях предупреждения и/или пресечения незаконных и/или противоправных действий Пользователей).
                        Раскрытие предоставленной Пользователем информации может быть произведено лишь в соответствии с
                        действующим законодательством Российской Федерации по требованию суда, правоохранительных
                        органов, а
                        равно в иных предусмотренных законодательством Российской Федерации случаях.
                      </li>
                      <li>
                        Выбранные Пользователем логин и пароль являются необходимой и достаточной информацией для
                        доступа Пользователя на Сайт. Пользователь не имеет права передавать свои логин и пароль третьим
                        лицам, несет полную ответственность за их сохранность, самостоятельно выбирая способ их
                        хранения.
                        Пользователь самостоятельно несет ответственность за безопасность (устойчивость к угадыванию)
                        выбранного им пароля, а также самостоятельно обеспечивает конфиденциальность своего пароля.
                      </li>
                      <li>
                        Если Пользователем не доказано обратное, любые действия, совершенные с использованием его
                        логина и пароля, считаются совершенными соответствующим Пользователем. В случае
                        несанкционированного
                        доступа к логину и паролю и/или официальной странице Пользователя, или распространения логина и
                        пароля
                        Пользователь обязан незамедлительно сообщить об этом Администрации Сайта в установленном
                        порядке.
                      </li>
                      <li>
                        После регистрации Пользователь получает возможность осуществить подписку на премиум услуги
                        Сервиса.
                      </li>
                      <li>
                        Пользователь согласен, что финансовые документы, предоставленные Пользователю за
                        предоставление премиум услуг сервиса в электронном виде, имеют юридическую силу при отсутствии
                        оригинала. Оригиналы финансовых документов предоставляются Администрацией сайта в офисе
                        Администрации
                        сайта только юридическим лицам, указанным в личном кабинете, либо пересылаются почтовым
                        отправлением
                        на адрес, указанный Пользователем, за отдельную плату.
                      </li>
                      <li>
                        Пароль Пользователя может быть восстановлен Администрацией Сервиса через форму
                        восстановления пароля.
                      </li>
                    </ol>
                  </div>

                  <div className="one_line_col">
                    <h2>5. Обязанности Пользователя</h2>
                    <ol>
                      <li>
                        При пользовании Услугами Сайта Пользователь обязан:
                        <ul>
                          <li>
                            соблюдать положения действующего законодательства Российской Федерации, настоящего
                            Соглашения и иных специальных документов Администрации Сайта;
                          </li>
                          <li>
                            предоставлять при регистрации достоверные, полные и актуальные данные;
                          </li>
                          <li>
                            информировать Администрацию Сайта о несанкционированном доступе к Официальной странице
                            и/или о несанкционированном доступе и/или использовании пароля и логина Пользователя;
                          </li>
                        </ul>
                      </li>
                      <li>
                        Пользователю при использовании Сайта запрещается:
                        <ol>
                          <li>
                            регистрироваться в качестве Пользователя от имени или вместо другого лица («фальшивый
                            аккаунт»), при этом, возможна регистрация от имени и поручению другого физического лица или
                            юридического лица при условии получения необходимых полномочий в порядке и форме,
                            предусмотренных
                            законодательством Российской Федерации;
                          </li>
                          <li>
                            незаконно загружать, хранить, публиковать, распространять и предоставлять доступ или иным
                            образом использовать интеллектуальную собственность третьих лиц;
                          </li>
                          <li>
                            использовать программное обеспечение и осуществлять действия, направленные на нарушение
                            нормального функционирования Сервиса;
                          </li>
                          <li>
                            использовать без специального на то разрешения Администрации Сайта автоматизированные
                            скрипты (программы) для сбора информации на Сайте и(или) взаимодействия с Сайтом и его
                            сервисами;
                          </li>
                          <li>
                            любым способом, в том числе, но не ограничиваясь, путем обмана, злоупотребления доверием,
                            взлома, пытаться получить доступ к логину и паролю другого Пользователя;
                          </li>
                          <li>
                            осуществлять незаконные сбор и обработку персональных данных других лиц;
                          </li>
                          <li>
                            осуществлять (пытаться получить) доступ к каким-либо Услугам иным способом, кроме как через
                            интерфейс, предоставленный Администрацией Сайта, за исключением случаев, когда такие
                            действия были
                            прямо разрешены Пользователю в соответствии с отдельным соглашением с Администрацией;
                          </li>
                          <li>
                            использовать любые способы оплаты, кроме предлагаемых Администрацией Сайта.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Пользователь несет личную ответственность за любую информацию, которую размещает в
                        приложении; такая деятельность осуществляется Пользователем на свой риск.
                      </li>
                      <li>
                        В случае несогласия Пользователя с настоящим Соглашением или его обновлениями, Пользователь
                        обязан отказаться от его использования, проинформировав об этом Администрацию Сервиса в
                        установленном
                        порядке.
                      </li>
                    </ol>
                  </div>

                  <div className="one_line_col">
                    <h2>6. Условия об интеллектуальных правах</h2>
                    <ol>
                      <li>
                        Правообладателем товарного знака Tusk, а также текстов, слоганов, изображений,
                        аудиовизуальных произведений и иного контента размещенного на сайте <a
                        href="https://tusk.best">https://tusk.best</a> и в
                        приложении
                        Tusk, является ИП Урсул Алексей Сергеевич. Любое использование товарного знака Tusk, а также
                        вышеперечисленных результатов интеллектуальной деятельности в сети Интернет допускается только с
                        согласия правообладателя. Любое изображение товарного знака должно содержать гиперссылку на сайт
                        <a href="https://tusk.best">https://tusk.best</a>. Использование изображения товарного знака
                        Tusk, наименования Tusk, в том
                        числе в
                        транслитерации на русском языке, а также вышеперечисленных результатов интеллектуальной
                        деятельности,
                        любыми способами, в том числе размещения их в сети Интернет, в печатных и других носителях без
                        согласования, рассматриваются как нарушение прав на товарный знак и результаты интеллектуальной
                        деятельности правообладателя.
                      </li>
                      <li>
                        Любое использование Контента Сервиса, кроме разрешенного в настоящем Соглашении или в случае
                        явно выраженного согласия правообладателя на такое использование, без предварительного
                        письменного
                        разрешения правообладателя, категорически запрещено.
                      </li>
                      <li>
                        Если иное явным образом не установлено в настоящем Соглашении, ничто в настоящем Соглашении
                        не может быть рассмотрено как передача исключительных прав на Контент.
                      </li>
                      <li>
                        Администрация вправе сохранять архивные копии пользовательского Контента в течение
                        неопределенного срока.
                      </li>
                    </ol>
                  </div>

                  <div className="one_line_col">
                    <h2>7. Ответственность. Ограничение ответственности</h2>
                    <ol>
                      <li>
                        Пользователи несут ответственность за собственные действия в связи с созданием и размещением
                        информации в приложении Tusk. Нарушение настоящего Соглашения и действующего законодательства
                        Российской Федерации влечет за собой гражданско-правовую, административную и уголовную
                        ответственность.
                      </li>
                      <li>
                        В информационной системе Сервиса и его программном обеспечении отсутствуют технические
                        решения, осуществляющие автоматические цензуру и контроль действий и информационных отношений
                        Пользователей по использованию Сервиса.
                      </li>
                      <li>
                        Администрация сохраняет за собой право в любое время изменять оформление Сайта, его
                        содержание, список сервисов, изменять или дополнять используемые скрипты, программное
                        обеспечение и
                        другие объекты, используемые или хранящиеся на Сайте, любые серверные приложения в любое время с
                        предварительным уведомлением или без такового.
                      </li>
                      <li>
                        Администрация Сервиса обеспечивает функционирование и работоспособность Сервиса и обязуется
                        оперативно восстанавливать его работоспособность в случае технических сбоев и перерывов.
                        Администрация
                        Сервиса не несет ответственности за временные сбои и перерывы в работе Сервиса и вызванные ими
                        потерю
                        информации. Администрация не несет ответственности за любой ущерб компьютеру Пользователя или
                        иного
                        лица, мобильным устройствам, любому другому оборудованию или программному обеспечению, вызванный
                        использованием Сервиса.
                      </li>
                      <li>
                        Администрация Сервиса имеет право направлять Пользователю информацию о развитии Сервиса, а
                        также рекламировать собственную деятельность и услуги.
                      </li>
                      <li>
                        Сервис, включая все скрипты, приложения, контент и оформление Сайта поставляются «как есть».
                        Администрация Сервиса отказывается от всяких гарантий того, что Сайт или его сервисы могут
                        подходить
                        или не подходить для конкретных целей использования. Администрация Сервиса не может
                        гарантировать и не
                        обещает никаких специфических результатов от использования Сервиса и/или его составляющих;
                      </li>
                      <li>
                        Стоимость, порядок и сроки оказания услуг описаны на странице Аккаунт, а также в Приложении
                        1.
                      </li>
                    </ol>
                  </div>
                </div>

                <div className="one_line_col">
                  <h2>8. Оплата премиум функций Tusk</h2>
                  <ol>
                    <li>
                      Чтобы использовать премиум функции приложения Tusk, необходимо иметь подключение к Интернету
                      и устройство, совместимое с сервисом Tusk, а именно: мобильное устройство с операционной системой
                      Android версии выше 6, либо мобильное устройство с операционной системой iOS версией выше 7, либо
                      персональный компьютер с операционной системой Microsoft Windows версией 8 и выше.
                    </li>
                    <li>
                      Подписка на премиум услуги производится на Сайте в личном кабинете пользователя (страница
                      Аккаунт). Пользователю предоставляется возможность выбрать один из нескольких вариантов оплаты, в
                      зависимости от длительности премиума.
                    </li>
                    <li>
                      Автоматическое списание денежных средств с банковской карты Пользователя не осуществляется.
                    </li>
                    <li>
                      Администрация Сервиса не хранит и не обрабатывает данные банковских карт Пользователя, а
                      также иные персональные данные Пользователя.
                    </li>
                    <li>
                      Администрация Сервиса ни при каких условиях не гарантирует возможность проведения Регулярных
                      платежей и операций по банковской карте Пользователя
                    </li>
                    <li>
                      Пользователь гарантирует, что он является держателем банковской карты, осознанно, корректно и
                      полностью вводит все требуемые реквизиты банковской карты при оплате используемых услуг.
                    </li>
                    <li>
                      По истечении оплаченного периода Ваша учётная запись автоматически лишается доступа к премиум
                      функциям, при этом все данные, созданные во время использования премиум доступа, сохраняются.
                    </li>
                    <li>
                      Мы не предоставляем возмещений или зачетов за частично неиспользованные периоды действия
                      сервисов или неполученную услугу Tusk.
                    </li>
                  </ol>
                </div>


                <div className="one_line_col">
                  <h2>9. Иные положения</h2>
                  <ol>
                    <li>
                      Настоящее Соглашение является соглашением между Пользователем и Администрацией Сайта
                      относительно порядка (правил и условий) использования Сайта и его сервисов и заменяют собой все
                      предыдущие соглашения между Пользователем и Администрацией;
                    </li>
                    <li>
                      Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской
                      Федерации. Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с
                      законодательством Российской Федерации.
                    </li>
                    <li>
                      В случае возникновения любых споров или разногласий, связанных с исполнением настоящего
                      Соглашения, Пользователь и Администрация Сервиса приложат все усилия для их разрешения путем
                      проведения переговоров между ними. В случае, если споры не будут разрешены путем переговоров,
                      споры
                      подлежат разрешению в порядке, установленном действующим законодательством Российской Федерации,
                      по
                      месту нахождения Администрации сайта.
                    </li>
                    <li>
                      Настоящее Соглашение вступает в силу для Пользователя с момента его присоединения к нему и
                      действует в течение неопределенного срока.
                    </li>
                    <li>
                      Настоящее Соглашение составлено на русском языке и может быть предоставлено Пользователю для
                      ознакомления на другом языке. В случае расхождения русскоязычной версии Соглашения и версии
                      Соглашения
                      на ином языке, применяются положения русскоязычной версии настоящего Соглашения.
                    </li>
                    <li>
                      Настоящее Соглашение может быть расторгнуто в следующих случаях:
                      <ol>
                        <li>
                          По инициативе Администрации Сайта за нарушение Пользователем Соглашения, законодательства
                          России или по требованию государственных органов России.
                        </li>
                        <li>
                          По инициативе Пользователя, при этом Пользователь обязан с контактного электронного адреса
                          Сайта уведомить Администрацию Сайта о расторжении настоящего Соглашения по электронной почте
                          на адрес
                          support@tusk.best за 10 календарных дней до даты расторжения Соглашения.
                        </li>
                        <li>
                          В случае одностороннего отказа Пользователя от Соглашения, при отсутствии вины
                          Администрации сайта, денежные средства возврату не подлежат и удерживаются Администрацией
                          Сайта. При
                          расторжении Соглашения Пользовательские данные удалятся Администрацией Сайта без возможности
                          восстановления.
                        </li>
                        <li>
                          Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут
                          признаны недействительными или не имеющими юридической силы, это не влечет недействительность
                          или
                          неприменимость остальных положений.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>

                <br/>

                <div className="one_line_col">
                  <h2># ПРИЛОЖЕНИЕ 1</h2>
                  <p>
                    После установки Tusk Пользователю автоматически доступен базовый функционал приложения.
                  </p>
                  <p>
                    Опции для оплаты премиум доступа:
                  </p>
                  <ol>
                    <li>
                      Оплата премиум доступа на месяц - стоимостью 79 рублей.
                    </li>
                    <li>
                      Оплата премиум доступа на 3 месяц - стоимостью 229 рублей.
                    </li>
                    <li>
                      Оплата премиум доступа на год - стоимостью 499 рублей.
                    </li>
                    <li>
                      Оплата бессрочного премиум доступа - стоимостью 1499 рублей.
                    </li>
                  </ol>
                  <p>

                    В премиум доступ входит:
                    <ul>
                      <li>
                        Облачная синхронизация между устройствами;
                      </li>
                      <li>
                        Синхронизация с Google Calendar;
                      </li>
                      <li>
                        Отсутствие рекламы;
                      </li>
                      <li>
                        Восстановление из облачного архива;
                      </li>
                      <li>
                        Неограниченные уведомления задач;
                      </li>
                      <li>
                        Теги задач, фильтрация по тегам;
                      </li>
                      <li>
                        Дополнительные цвета для задач;
                      </li>
                      <li>
                        Журнал выполнений;
                      </li>
                      <li>
                        Экран блокировки;
                      </li>
                      <li>
                        Выходные;
                      </li>
                      <li>
                        Шаблоны задач;
                      </li>
                      <li>
                        Emoji в качестве иконок для задач;
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </BasicPage>
    )
  }
}

export default TermsAndConditionsRu