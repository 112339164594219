import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { auth, sendPasswordReset } from '../../services/firebase'
import './Reset.scss'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Modal } from 'react-bootstrap'

function Reset() {
  const [email, setEmail] = useState('')
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [showDialog, setShowDialog] = useState(false)

  const handleClose = () => {
    setShowDialog(false)
    navigate('/login')
  }

  useEffect(() => {
    if (loading) return
    if (user) navigate('/')
  }, [user, loading])

  return (
    <BasicPage>
      <div className="reset">
        <div className="reset__container">
          <div className="text-start mb-4">
            <Link to="/login">&#60;&#60;&nbsp;&nbsp;{ t('back') }</Link>
          </div>

          <input
            type="text"
            className="reset__textBox"
            value={ email }
            onChange={ (e) => setEmail(e.target.value) }
            placeholder={ t('email') }
          />
          <button
            className="reset__btn"
            onClick={ () => {
              sendPasswordReset(email)
              setShowDialog(true)
            } }
          >
            { t('reset_password') }
          </button>
          <div>
            <Trans i18nKey="register_now">
              Don't have an account? <Link to="/register">Register</Link> now.
            </Trans>
          </div>

          <Modal show={ showDialog } onHide={ handleClose }>
            <Modal.Header closeButton>
              <Modal.Title>{ t('email_has_been_sent') }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="text-start">
                { t('password_reset_description') }
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={ handleClose }>OK</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </BasicPage>
  )
}

export default Reset