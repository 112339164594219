import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, logout } from '../../services/firebase'
import { Link } from 'react-router-dom'
import { LogIn, LogOut } from 'react-feather'
import './LoginButton.scss'
import { useTranslation } from 'react-i18next'

function LoginButton() {
  const [user] = useAuthState(auth)
  const {t} = useTranslation()

  if (user === null) {
    return (
      <Link to="/login" className="login-button d-flex align-items-center">
        <LogIn className="me-2" size="20"/>
        { t('login') }
      </Link>
    )
  }

  return (
    <Link to="/" className="login-button d-flex align-items-center" onClick={ logout }>
      <LogOut className="me-2" size="20"/>
      <span>{ t('logout') }</span>
    </Link>
  )
}

export default LoginButton