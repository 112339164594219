import React from 'react'
import axios from 'axios'
import { withTranslation } from 'react-i18next'
import { Alert } from 'react-bootstrap'
import { auth } from '../../services/firebase'
import './GetPremium.scss'
import BuyPremiumCard from '../BuyPremiumCard/BuyPremiumCard'
import { Link } from 'react-router-dom'

class GetPremium extends React.Component {
  firstMount = true

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      countryCode: '',
      shouldRedirect: false,
      prices: [],
      showPrices: false,
      trialIsAvailable: false
    }
    this.getFreePremium = this.getFreePremium.bind(this)
  }

  createPayment(type) {
    const data = {
      'uuid': auth.currentUser.uid,
      'periodType': type,
      // 'returnUrl': 'http://localhost:3000/account/success'
      'returnUrl': 'https://tusk.best/account/success'
    }
    axios.post('https://tusk.best/api/yookassa/createPayment', data).then((response) => {
      window.location = response.data.url
    })
  }

  getFreePremium() {
    axios.post('https://tusk.best/api/subscription/getTrial?uuid=' + auth.currentUser.uid).then((_) => {
      this.props.onRefresh()
    })
  }


  checkTrialPremium() {
    axios.post('https://tusk.best/api/subscription/getTrial', {
      'uuid': auth.currentUser.uid,
      'check': true
    }).then((response) => {
      this.setState({trialIsAvailable: response.data.received === false})
    })
  }

  componentDidMount() {
    if (this.firstMount) {
      this.firstMount = false
      axios.get('https://api.ipregistry.co/5.130.0.238?key=r2q5iyrmn01frb2v&fields=location.country.code').then(async (response) => {
        if (response.data != null) {
          let country = response.data.location?.country?.code.toUpperCase() ?? ''
          this.setState({countryCode: country})

          if (country === 'RU') {
            let pricesResponse = await axios.get('https://tusk.best/api/yookassa/getPrices')
            if (pricesResponse.data != null) {
              this.setState({loading: false, prices: pricesResponse.data, showPrices: true})
            } else {
              this.setState({loading: false, showPrices: false})
            }

            this.checkTrialPremium()
          } else {
            this.setState({loading: false, showPrices: false})
          }
        }
      })
    }

  }

  render() {
    const {t} = this.props

    if (this.state.loading) {
      return
    }

    if (this.state.countryCode === 'RU') {
      if (!this.state.showPrices) {
        return <div className="mb-2"/>
      }

      let getTrialOption = <span/>
      if (this.state.trialIsAvailable) {
        getTrialOption = (
          <BuyPremiumCard
            onClick={ () => this.getFreePremium() }
            label="Бесплатный 7-дневный премиум"
            price={ 0 }
          />
        )
      }

      return (
        <div className="mb-2">
          <p>Приобрести доступ к премиум функциям Tusk:</p>
          <div>
            { getTrialOption }
            <BuyPremiumCard
              onClick={ () => this.createPayment('monthly') }
              label={ this.state.prices['monthly'].label }
              price={ this.state.prices['monthly'].price }
            />
            <BuyPremiumCard
              onClick={ () => this.createPayment('3months') }
              label={ this.state.prices['3months'].label }
              price={ this.state.prices['3months'].price }
            />
            <BuyPremiumCard
              onClick={ () => this.createPayment('yearly') }
              label={ this.state.prices['yearly'].label }
              price={ this.state.prices['yearly'].price }
            />
            <BuyPremiumCard
              onClick={ () => this.createPayment('lifetime') }
              label={ this.state.prices['lifetime'].label }
              price={ this.state.prices['lifetime'].price }
            />
          </div>
          <div className="mb-4 mt-3 text-start">
            <ul>
              <li>
                <Link to="/terms_and_conditions">{ t('terms_and_conditions') }</Link>
              </li>
              <li>
                <Link to="/privacy_policy">{ t('privacy_policy') }</Link>
              </li>
            </ul>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Alert variant="warning">{ t('you_can_purchase_premium') }</Alert>
      </div>
    )
  }
}

export default withTranslation()(GetPremium)