import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth, logInWithEmailAndPassword, signInWithGoogle } from '../../services/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import './Login.scss'
import BasicPage from '../../layouts/BasicPage/BasicPage'
import { Trans, useTranslation } from 'react-i18next'
import validator from 'validator'
import { useDispatch } from 'react-redux'
import { fetchExpiresAsync } from '../Account/accountSlice'

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [loginError, setLoginError] = useState(null)
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const signIn = () => {
    const emailFailed = !validator.isEmail(email)
    const passwordFailed = password.length === 0
    setLoginError(null)
    setEmailError(emailFailed)
    setPasswordError(passwordFailed)
    if (emailFailed || passwordFailed) {
      return
    }

    logInWithEmailAndPassword(email, password).then(errorMessage => {
      if (errorMessage == null) {
        return
      }

      let message = errorMessage
      if (errorMessage === 'auth/wrong-password') {
        message = t('wrong_password')
      } else if (errorMessage === 'auth/user-not-found') {
        message = t('wrong_password')
      }

      setEmailError(false)
      setPasswordError(false)
      setLoginError(message)
    })
  }

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) {
      dispatch(fetchExpiresAsync(user.uid))
      navigate('/account')
    }
  }, [user, loading])
  return (
    <BasicPage>
      <div className="login">
        <div className="login__container">
          <input
            type="text"
            className={ 'login__textBox ' + (emailError ? 'is-invalid' : '') }
            value={ email }
            onChange={ (e) => setEmail(e.target.value) }
            placeholder={ t('email') }
          />

          { emailError && <p>{ t('enter_valid_email') }</p> }

          <input
            type="password"
            className={ 'login__textBox ' + (passwordError ? 'is-invalid' : '') }
            value={ password }
            onChange={ (e) => setPassword(e.target.value) }
            placeholder={ t('password') }
          />

          { passwordError && <p>{ t('enter_password') }</p> }

          { loginError !== null && <p className="fw-bold">{ loginError }</p> }

          <button
            className="login__btn"
            onClick={ () => signIn() }
          >
            { t('login') }
          </button>
          <button className="login__btn login__google" onClick={ signInWithGoogle }>
            { t('login_with_google') }
          </button>
          <div>
            <Link to="/reset">{ t('reset_password') }</Link>
          </div>
          <div>
            <Trans i18nKey="register_now">
              Don't have an account? <Link to="/register">Register</Link> now.
            </Trans>
          </div>
        </div>
      </div>
    </BasicPage>
  )
}

export default Login