import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../components/counter/counterSlice';
import accountReducer from '../pages/Account/accountSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    account: accountReducer,
  },
});
